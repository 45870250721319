.footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 5vh;
  width: 100%;
  border-top: 2px solid black;
  padding: 1rem 0.5rem;
}

.contact-options {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 20%;
  height: auto;
}

svg {
  width: 37%;
  height: 100%;
}
