.projects-body {
  display: flex;
  height: 95vh;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.projects-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
