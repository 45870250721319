.text-container {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  /* direction: initial; */
  width: 40%;
  display: flex;
  flex-direction: column;
}

.color-1 {
  font-weight: 700;
}

.color-2 {
  font-weight: 800;
  color: #7c3aed;
}

.brief-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* padding: 0 2rem; */
  height: 95vh;
  margin: 2rem;
  width: 100%;
}

.brief-image {
  width: 20%;
  height: 30%;
}

.brief-heading {
  margin: 10px 0;
  font-size: 4rem;
}

.brief-text {
  font-size: x-large;
}
