.description-container {
  height: 95vh;
  padding: 0 5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.desctiprion-text-container {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.color-1 {
  font-weight: 700;
}

.color-2 {
  font-weight: 800;
  color: #7c3aed;
}

.description-heading {
  font-size: 4rem;
}

.description-text {
  font-size: larger;
}
