.project-tile {
  height: 50vh;
  background-color: #1c1c2d;
  border: 2px solid #6c63ff;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 300px; /* Adjust this width based on your design */
  text-align: center;
  color: #ffffff;
  box-shadow: 0 4px 8px #00000033;
  transition: transform 0.2s;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
}

.project-tile:hover {
  transform: scale(1.05);
}

.project-text-container {
  padding: 10px 0;
}

.project-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.project-description {
  font-size: 16px;
  margin-bottom: 20px;
}

.links-container {
  display: flex;
  justify-content: space-evenly;
}

.links-container button {
  text-decoration: none;
  color: #ffffff;
  background-color: #6c63ff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
}

.links-container button:hover {
  background-color: #5348c9;
}

.project-img {
  height: 66%;
  width: 66%;
}
