* {
  padding: 0;
  margin: 0;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 20px;
  border-bottom: 2px solid black;
  width: 98vw;
  height: 5vh;

  background-color: white;
  position: fixed;
}

.nav-links-container {
  display: flex;
}

.link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
}

.link-element {
  margin: 5px;
}

a {
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
  color: black;
}

.profile-img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
