.download-area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95vh;
  /* margin: 5rem; */
  flex-flow: column;
}

.download-button {
  margin: 2rem 0;
  height: 40px;
  padding: 10px;
  border: 1px solid black;
  text-align: center;
  background-color: aqua;
  border-radius: 7px;
}

.resume-img {
  height: 100%;
  padding: 6rem 0 0 0;
}
